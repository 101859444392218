import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Dashbot Terms of Service`}</h2>
    <p>{`These Terms of Service were last updated on March 3, 2019.`}</p>
    <p>{`The following are the terms and conditions for use of Dashbot’s Bot Analytics Platform (the “Service”) between Dashbot Inc. and you (either an individual or a legal entity that you represent as an authorized employee or agent) (“You”). BY CLICKING THE “I ACCEPT” BUTTON, AND/OR ACCESSING OR USING THE SERVICE, YOU ARE STATING THAT YOU ARE ELIGIBLE FOR AN ACCOUNT AND THAT YOU AGREE TO BE BOUND BY ALL OF THESE TERMS AND CONDITIONS (“AGREEMENT”). The Service is offered to you conditioned on your acceptance of the terms and conditions contained herein. If You have entered into a separate written agreement (“Order Form”) with Dashbot governing your use of the Service, then the terms and conditions of that Order Form will prevail and govern, and any conflicting terms of this Agreement will not be given effect.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`DEFINITIONS`}</strong></li>
    </ol>
    <p>{`“API Request” is the unit of measurement for usage of the Service. An API Request occurs when the SDK is executed in a bot application or bot server to send data to, or request data from, the Service. An API Request also occurs when another service or website programmatically sends data to, or requests data from, the Service.`}</p>
    <p>{`“Customer Data” means the data concerning the characteristics and activities of visitors to your Bot Applications that is collected through use of the SDK and analyzed by the Software.`}</p>
    <p>{`“Documentation” means any accompanying documentation made available to You by Dashbot for use with the Software, including any API documentation.`}</p>
    <p>{`“Bot Applications” means Your bot applications and/or bot services.`}</p>
    <p>{`“Report” means the resulting analysis provided through the Services for Your Bot Applications.`}</p>
    <p>{`“SDK” means the library of code, which is installed in a Bot Application, server, or on a web page for the purpose of collecting Customer Data, together with any fixes, updates, and upgrades provided to you by Dashbot.`}</p>
    <p>{`“Software” means Dashbot’s proprietary Service software and servers (including, without limitation, the SDK), which analyzes the Customer Data and generates the Reports.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`ACCOUNT`}</strong></li>
    </ol>
    <p>{`You must complete the registration process by providing Dashbot with current, complete and accurate information as prompted by the registration form, including Your e-mail address (username) and password in order to use the Service. You are responsible for safeguarding the confidentiality of Your password(s) and user name(s), and for any use or misuse of Your account resulting from any third party using a password or user name. By creating an account, You represent and warrant that You are the legal owner (or an authorized employee or agent) of the Bot Application for which the Service will be provided through Your account.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`FEES`}</strong></li>
    </ol>
    <p>{`The Service is currently provided without charge to You for up to 1,000,000 API Requests per month per account. You must purchase (or Your Account will be converted to) a paid version of the Service if You exceed this limit. Dashbot reserves the right to limit or prevent Your access to the Service, including any Reports, if You are exceeding this limit and have not converted to a paid edition. Dashbot may change its fees and payment policies for the Service from time to time in its sole and absolute discretion including but not limited to a change in the number of API Requests, the addition of costs for certain services, or other fees charged to Dashbot or its licensors by 3rd party vendors for the inclusion of data in the Reports. The changes to the fees or payment policies are effective upon (i) Your acceptance of such changes which You will be informed of through your account, or (ii) Your continued use of the Service after notification of the changes to the fees and/or payment policies. If You do not accept such changes, Dashbot reserves the right to limit, prevent, or terminate Your access to the Service without any notice or liability. Unless otherwise stated, all fees are quoted in U.S. Dollars and are due and payable on a monthly basis within 30 days from receipt of invoice. Any outstanding balance becomes immediately due and payable upon termination of this Agreement for any reason and any collection expenses (including attorneys’ fees) incurred by Dashbot will be included in the amount owed.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><strong parentName="li">{`LICENSE`}</strong></li>
    </ol>
    <p>{`Subject to Your compliance with the terms and conditions of this Agreement, Dashbot hereby grants You a limited, revocable, non-exclusive, non-sublicensable license to (i) install, copy and use the SDK solely as necessary to use the Service for Your Bot Applications, and (ii) use the Documentation solely for the purposes of developing, implementing and integrating interfaces to the Service for your Bot Applications. Subject to the terms and conditions of this Agreement, You may remotely access, view and download Your Reports made available by Dashbot. The Reports are for Your internal business purposes only. You may not sell, rent, license, disclose or make the Reports publicly available without the permission of Dashbot.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`RESTRICTIONS`}</strong></li>
    </ol>
    <p>{`You will not nor will You allow any third party to (i) copy, modify, adapt, translate or otherwise create derivative works of the Software, or the Documentation; (ii) reverse engineer, de-compile, disassemble or otherwise attempt to discover the source code of the Software, except as expressly permitted by the law in effect in the jurisdiction in which You are located; (iii) rent, lease, sell, assign or otherwise transfer rights in or to the SDK, the Software, the Documentation or the Service; (iv) remove any proprietary notices or labels on the Software or placed by the Service; or (v) use, post, transmit or introduce any device, software or routine which interferes or attempts to interfere with the operation of the Service or the Software. You will use the Software, Service and Reports solely for Your own internal use, and will not make the Software or Service available for timesharing, application service provider or service bureau use. You will comply with all applicable laws and regulations in Your use of and access to the Documentation, Software, Service and Reports.`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`INFORMATION RIGHTS`}</strong></li>
    </ol>
    <p>{`Dashbot may retain and use information collected in Your use of the Service, including Customer Data, in order to provide the Service to you, and to improve the Service. You shall ensure that Your Terms of Service and Privacy Policy for Your users shall allow Dashbot to process Customer Data in accordance with this Terms of Service, including, without limitation, allowing Dashbot to collect, use, and resell Customer Data in non-personally identifiable format. Dashbot will not share information associated with You or your Bot Applications with any third parties unless Dashbot (i) has Your consent; (ii) concludes that it is required by law or has a good faith belief that access, preservation or disclosure of such information is reasonably necessary to protect the rights, property or safety of Dashbot, its users or the public; or (iii) provides such information in anonymous or aggregated form.`}</p>
    <p>{`To the extent that We process any personal data on Your behalf in the course of providing the Service, and either (i) You are established in the European Economic Area (“EEA”), or (ii) the data relates to residents of the EEA, We will do so in accordance with the data processing addendum accessible at `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/legal/dpa/"
      }}>{`https://www.dashbot.io/legal/dpa`}</a>{`, which forms part of these Terms of Service and is incorporated herein. For the purposes of this clause, the terms “personal data” and “process” have the meaning given in the data processing addendum.`}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol"><strong parentName="li">{`PRIVACY`}</strong></li>
    </ol>
    <p>{`Except as may be specifically set forth herein, You will not (and will not allow any third party to) use the Service to track or collect personally identifiable information of Internet users, nor will You (or will You allow any third party to) associate any data gathered from Your Bot Applications (or such third parties’ website(s) or applications) with any personally identifying information from any source as part of Your use (or such third parties’ use) of the Service. You will have and abide by an appropriate privacy policy which shall meet and conform in all respects to the requirements set forth in Section 6 above. You shall also comply with all applicable laws relating to the collection of information from visitors to Your Bot Applications. You must post a privacy policy and that policy must (i) meet and confirm in all respects to the requirements set forth in Section 6 above, and (ii) provide notice of your use of a cookie and SDK that collects user information through the Service.`}</p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol"><strong parentName="li">{`PROPRIETARY RIGHTS`}</strong></li>
    </ol>
    <p>{`The Service (which includes but is not limited to the SDK, the Software, all intellectual property rights in the Service and all non-personally identifiable data obtained through the Service) are, and shall remain, the property of Dashbot. All rights in and to the Software and Service not expressly granted to You in this Agreement are hereby expressly reserved and retained by Dashbot and its licensors.`}</p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol"><strong parentName="li">{`CONFIDENTIALITY`}</strong></li>
    </ol>
    <p>{`“Confidential Information” includes any proprietary data and any other non-public information disclosed by one party to the other that is marked (if in writing) or identified (if disclosed orally) as “confidential”. Notwithstanding the foregoing, Confidential Information will not include any information that is or becomes known to the general public, which is already in the receiving party’s possession prior to disclosure by a party or which is independently developed by the receiving party without the use of Confidential Information. Neither party will use or disclose the other party’s Confidential Information without the other’s prior written consent except for the purpose of performing its obligations under this Agreement or if required by law, regulation or court order. In which case, the party being compelled to disclose Confidential Information will give the other party as much notice as is reasonably practicable prior to disclosing such information. Upon termination of this Agreement, the parties will promptly either return or destroy all Confidential Information and, upon request, provide written certification of such.`}</p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol"><strong parentName="li">{`SERVICE LEVELS`}</strong></li>
    </ol>
    <p>{`Dashbot does not guarantee the Service will be operable at all times or during any down time (1) caused by outages to any public Internet backbones, networks or servers, (2) caused by any failures of Your equipment, systems or local access services, (3) for previously scheduled maintenance or (4) relating to events beyond Dashbot’s (or its licensors’ or service provider’s) control such as strikes, riots, insurrection, fires, floods, explosions, war, governmental action, labor conditions, earthquakes, natural disasters, or interruptions in Internet services to an area where Dashbot or Your servers are located or co-located.`}</p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol"><strong parentName="li">{`TERMINATION`}</strong></li>
    </ol>
    <p>{`You may terminate Your use of the Service at any time and for any reason. Dashbot may also suspend your access to the Service or terminate this Agreement at any time for any reason. Upon any termination of this Agreement, Dashbot will cease providing the Service, and You will delete all copies of SDK from all Bot Applications and will cease any further access or use of the Service. In the event of any termination (a) You will not be entitled to any refunds of any fees, and (b) any (i) outstanding balance for Service rendered through the date of termination, and (ii) other unpaid payment obligations during the remainder of the Term will be immediately due and payable in full and (c) all of Your historical report data will no longer be available to You unless a purchase or professional services agreement for the exchange and transfer of such data is entered into as a component of termination. The following sections of this Agreement will survive termination: 1, 5, 6, 7, 8, 9, 11, 12, 13, 14, and 17.`}</p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol"><strong parentName="li">{`INDEMNIFICATION`}</strong></li>
    </ol>
    <p>{`You agree to indemnify, hold harmless and defend Dashbot from any and all third-party claims, actions, proceedings, and suits brought against Dashbot or any of its officers, directors, employees, agents or affiliates, and all related liabilities, damages, settlements, penalties, fines, costs or expenses (including, without limitation, reasonable attorneys’ fees and other litigation expenses) incurred by Dashbot arising out of or relating to (i) Your breach of any term or condition of this Agreement (including, without limitation, Section 6), (ii) Your unauthorized use of the Service, or (iii) Your violations of applicable laws, rules or regulations in connection with your use of the Service, the Software and/or Your Bot Applications. In such a case, Dashbot will provide You with written notice of such claim, suit or action.`}</p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol"><strong parentName="li">{`DISCLAIMER OF WARRANTIES`}</strong></li>
    </ol>
    <p>{`The information and services included in or available through the Service, including the Reports, may include inaccuracies or typographical errors. Dashbot does not represent or warrant that the Service or the Software will be uninterrupted or error-free, that all defects will be corrected, or that the Service or the Software are completely free of viruses or other harmful components. Dashbot does not warrant or represent that the use of the Service, Software or the Reports will be correct, accurate, timely or otherwise reliable. You specifically agree that Dashbot will not be responsible for unauthorized access to or alteration of the Customer Data or data from Your Bot Applications. THE SERVICE, THE SOFTWARE AND REPORTS ARE PROVIDED “AS IS” AND THERE ARE NO WARRANTIES, CLAIMS OR REPRESENTATIONS MADE BY DASHBOT AND/OR ITS LICENSORS, EITHER EXPRESS, IMPLIED, OR STATUTORY, WITH RESPECT TO THE SERVICE, THE SOFTWARE, THE DOCUMENTATION AND REPORTS, INCLUDING WARRANTIES OF QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE, OR TRADE USAGE. THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF THIS AGREEMENT AND FORMED THE BASIS FOR DETERMINING THE PRICE CHARGED FOR THE SERVICE. Some states do not allow exclusion of an implied warranty, so this disclaimer may not apply to You.`}</p>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol"><strong parentName="li">{`LIMITATION OF LIABILITY`}</strong></li>
    </ol>
    <p>{`DASHBOT WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL (INCLUDING, WITHOUT LIMITATION, LOST PROFITS OR LOST DATA COLLECTED THROUGH THE SERVICE), OR INCIDENTAL DAMAGES, WHETHER BASED ON A CLAIM OR ACTION OF CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR OTHER TORT, BREACH OF ANY STATUTORY DUTY, INDEMNITY OR CONTRIBUTION, OR OTHERWISE, EVEN IF DASHBOT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. The exclusion contained in this paragraph shall apply regardless of the failure of the exclusive remedy provided in the following paragraph. Some states do not allow the limitation or exclusion of liability for incidental or consequential damages, so the foregoing limitations or exclusions may not apply to You. Dashbot’s total cumulative liability for any loss or damages resulting from any claims, demands, or actions arising out of or relating to this Agreement shall not exceed the amount paid by You in the 12 months preceding the claim.`}</p>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol"><strong parentName="li">{`US GOVERNMENT RIGHTS`}</strong></li>
    </ol>
    <p>{`If the use of the Service is being acquired by or on behalf of the U.S. Government or by a U.S. Government prime contractor or subcontractor (at any tier), in accordance with 48 C.F.R. 227.7202-4 (for Department of Defense (DOD) acquisitions) and 48 C.F.R. 2.101 and 12.212 (for non-DOD acquisitions), the Government’s rights in the Software, including its rights to use, modify, reproduce, release, perform, display or disclose the Software or Documentation, will be subject in all respects to the commercial license rights and restrictions provided in this Agreement.`}</p>
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol"><strong parentName="li">{`MODIFICATIONS`}</strong></li>
    </ol>
    <p>{`Dashbot reserves the right to change or modify any of the terms and conditions contained in this Agreement or any policy governing the Service, at any time, by posting the new agreement, which You will be informed of through your account. You are responsible for regularly reviewing the policy. No amendment to or modification of this Agreement will be binding unless (i) in writing and signed by a duly authorized representative of Dashbot, (ii) You accept updated terms online, or (iii) You continue to use the Service after Dashbot has posted updates to the Agreement or to any policy governing the Service.`}</p>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol"><strong parentName="li">{`GENERAL`}</strong></li>
    </ol>
    <p>{`This Agreement (including any amendment agreed upon by the parties in writing) represents the complete agreement between You and Dashbot concerning its subject matter, and supersedes all prior agreements and representations between the parties. If any provision of this Agreement is held to be unenforceable for any reason, such provision shall be reformed to the extent necessary to make it enforceable to the maximum extent permissible so as to affect the intent of the parties, and the remainder of this Agreement shall continue in full force and effect. A waiver of any default is not a waiver of any subsequent default. You may not assign or otherwise transfer any of Your rights hereunder without Dashbot’s prior written consent, and any such attempt is void. The relationship between Dashbot and You is not one of a legal partnership relationship, but is one of independent contractors. This Agreement shall be binding upon and inure to the benefit of the respective successors and assigns of the parties hereto. This Agreement shall be governed by and construed under the laws of the state of California without reference to its conflict of law principles. In the event of any conflicts between foreign law, rules, and regulations, and California law, rules, and regulations, California law, rules and regulations shall prevail and govern. Each party agrees to submit to the exclusive and personal jurisdiction of the courts located in San Francisco County, California.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      