import React from 'react';
import SEO from '../components/seo';
import Footer from '../components/footer/footer';
import styles from './base.module.scss';
import { Container } from 'react-bootstrap';

const Default = ({ children }) => {
  return (
    <React.Fragment>
      <SEO title={`Dashbot`} />
      <main className={styles.defaultWrapper}>
        <Container>{children}</Container>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Default;
